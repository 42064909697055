import type { ProductDto } from "~~/models/products";

export function useProductComparisonManager() {
  const comparingProducts = useProductComparisonList();

  const comparingProductsArray = computed<Array<ProductDto>>(() => {
    return Array.from(comparingProducts.value);
  });

  const hasSelectedProducts = computed<boolean>(() => {
    return comparingProducts.value.size > 0;
  });

  const canSelectMoreProducts = computed<boolean>(() => {
    return comparingProducts.value.size < 3;
  });

  const selectProduct = (product: ProductDto) => {
    if (!canSelectMoreProducts.value) {
      return;
    }

    comparingProducts.value.add(product);
  };

  const deselectProduct = (product: ProductDto) => {
    comparingProducts.value.delete(product);
  };

  const deselectAllProducts = () => {
    comparingProducts.value.clear();
  };

  const isProductSelected = (product: ProductDto) => {
    return comparingProducts.value.has(product);
  };

  return {
    selectedProducts: comparingProductsArray,
    hasSelectedProducts: hasSelectedProducts,
    canSelectMoreProducts: canSelectMoreProducts,
    selectProduct: selectProduct,
    deselectProduct: deselectProduct,
    deselectAllProducts: deselectAllProducts,
    isProductSelected: isProductSelected,
  };
}
